<template>
  <div>
    <vx-card>
      <plant-form v-model="updatePlant" />
      <div class="flex flex-row-reverse m-6">
        <vs-button
          @click="submit"
          class="mb-4 md:mb-0"
          data-test="submitUpdatePlantBtn"
          >Guardar</vs-button
        >
        <vs-button @click="regresar" class="mr-2" type="flat"
          >Descartar</vs-button
        >
      </div>
    </vx-card>
  </div>
</template>

<script>
import { UPDATE_PLANT } from '@/graphql/mutations';
import { PLANTS_PAGINATED, PLANT } from '@/graphql/queries';
import PlantForm from '../../components/plantas/PlantForm.vue';
import _ from 'lodash';
export default {
  name: 'UpdatePlant',
  components: {
    PlantForm,
  },
  data: () => ({
    updatePlant: {
      availability: {},
    },
  }),
  mounted() {},
  apollo: {
    plant: {
      query: PLANT,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (!data?.plant) return;
        this.updatePlant = data.plant;
        this.updatePlant.franchise_id = data.plant.franchise.id;
        this.updatePlant = _.omit(this.updatePlant, [
          '__typename',
          'franchise',
          'availability.__typename',
          'availability.monday',
          'availability.tuesday',
          'availability.wednesday',
          'availability.thursday',
          'availability.friday',
          'availability.saturday',
          'availability.sunday',
          'created_at',
          'updated_at',
        ]);
      },
    },
  },

  methods: {
    submit() {
      this.updatePlant = _.omit(this.updatePlant, [
        '__typename',
        'availability.__typename',
        'availability.id',
      ]);
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: UPDATE_PLANT,
          variables: {
            input: this.updatePlant,
          },
          update: (cache, { data: { updatePlant } }) => {
            try {
              cache.writeQuery({
                query: PLANT,
                variables: {
                  id: updatePlant.id,
                },
                data: updatePlant,
              });
            } catch {
              console.log();
            }
            try {
              const data = cache.readQuery({
                query: PLANTS_PAGINATED,
                variables: {
                  page: 1,
                  first: 15,
                },
              });
              cache.writeQuery({
                query: PLANTS_PAGINATED,
                variables: {
                  page: 1,
                  first: 15,
                },
                data,
              });
            } catch {
              console.log();
            }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Planta Actualizada',
            text: 'La planta se actualizó correctamente.',
          });
          this.$router.push('/plantas');
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Error Interno',
            text: 'Error al actualizar la planta',
          });
        });
    },
    regresar() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
